import { CommonModule, NgOptimizedImage } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  ViewChild,
  inject,
} from '@angular/core';
import {
  Router,
  RouterLink,
  RouterLinkActive,
  RouterOutlet,
} from '@angular/router';
import {
  RdsAvatarComponent,
  RdsAvatarImageDirective,
  RdsButtonModule,
  RdsDividerModule,
  RdsDropdownDirective,
  RdsDropdownModule,
  RdsHeaderModule,
  RdsIconComponent,
  RdsMenuModule,
} from '@rds/angular-components';

import {
  AuthService,
  DestinationFrontendEnum,
  NotificationsDropdownComponent,
  NotificationsService,
  PushNotificationsService,
} from '@bookly/shared';

import { RoomFiltersMenuComponent } from '../rooms/components/room-filters-menu/room-filters-menu.component';

@Component({
  selector: 'bus-dashboard',
  imports: [
    CommonModule,
    RdsDividerModule,
    NgOptimizedImage,
    NotificationsDropdownComponent,
    RdsAvatarComponent,
    RdsAvatarImageDirective,
    RdsHeaderModule,
    RdsDropdownModule,
    RdsButtonModule,
    RouterOutlet,
    RdsIconComponent,
    RdsMenuModule,
    RouterLink,
    RouterLinkActive,
    RoomFiltersMenuComponent,
  ],
  templateUrl: './user-dashboard.component.html',
  styleUrl: './user-dashboard.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserDashboardComponent implements OnInit {
  readonly #authService = inject(AuthService);
  readonly #notificationService = inject(NotificationsService);
  readonly #pushNotificationService = inject(PushNotificationsService);
  protected readonly router = inject(Router);
  protected readonly user = this.#authService.user;
  protected readonly isHost = this.#authService.isHost;

  @ViewChild('desktopDropdown', { read: RdsDropdownDirective })
  desktopMenuDropdown!: RdsDropdownDirective;

  @ViewChild('mobileDropdown', { read: RdsDropdownDirective })
  mobileMenuDropdown!: RdsDropdownDirective;

  ngOnInit(): void {
    void this.#pushNotificationService.init();
    this.#notificationService
      .getNotifications(DestinationFrontendEnum.User)
      .subscribe();
  }

  protected logout() {
    this.mobileMenuDropdown.hide();
    this.desktopMenuDropdown.hide();
    void this.#authService.logout();
  }
}
