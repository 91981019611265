@let roomFilters = roomFiltersData();
@let isMobileScreen = isMobileScreen$ | async;

<div class="filters-wrapper" [formGroup]="filtersForm">
  <p class="ui-label-m-bold c-700-grey-1">Room Name</p>
  <rds-form-field labelPosition="none" size="m">
    <input
      rdsInput
      placeholder="Search the site..."
      formControlName="roomName" />
  </rds-form-field>
  @if (roomFilters && roomFilters.locations) {
    <p class="ui-label-m-bold lmt-2 c-700-grey-1">Location</p>
    <div class="select-controls-wrapper">
      <rds-form-field labelPosition="none" size="m">
        <rds-select placeholder="City" formControlName="siteId">
          @for (location of roomFilters.locations; track location.id) {
            <rds-select-option [value]="location.id">{{
              location.name
            }}</rds-select-option>
          }
        </rds-select>
      </rds-form-field>
      <rds-form-field labelPosition="none" size="m">
        <rds-select
          [placeholder]="
            this.filtersForm.controls.siteId.value
              ? 'Building'
              : 'Building - select city'
          "
          formControlName="buildingId">
          @for (building of buildings(); track building.id) {
            <rds-select-option [value]="building.id">{{
              building.name
            }}</rds-select-option>
          }
        </rds-select>
      </rds-form-field>
      <rds-form-field labelPosition="none" size="m">
        <rds-select
          [placeholder]="
            this.filtersForm.controls.buildingId.value
              ? 'Floor'
              : 'Floor - select building'
          "
          formControlName="floorId">
          @for (floor of floors(); track floor.id) {
            <rds-select-option [value]="floor.id">{{
              floor.name
            }}</rds-select-option>
          }
        </rds-select>
      </rds-form-field>
    </div>
  }

  <p class="ui-label-m-bold lmt-2 c-700-grey-1">Capacity</p>
  <div class="controls-wrapper">
    <rds-form-field labelPosition="none" size="m">
      <input
        rdsInput
        placeholder="Min"
        formControlName="minCapacity"
        type="number" />
    </rds-form-field>
    <p class="ui-label-m c-700-grey-1">to</p>
    <rds-form-field labelPosition="none" size="m">
      <input
        rdsInput
        placeholder="Max"
        formControlName="maxCapacity"
        type="number" />
    </rds-form-field>
  </div>

  <p class="ui-label-m-bold lmt-2 c-700-grey-1">Available On</p>
  <div class="dates-wrapper">
    <rds-form-field labelPosition="none" size="m">
      <input
        rdsInput
        [rdsDatepicker]="datePickerFrom"
        formControlName="fromDate" />
      @if (filtersForm.controls.fromDate.value) {
        <rds-datepicker-clear
          rds-control-suffix
          [forDatepicker]="datePickerFrom" />
      } @else {
        <rds-datepicker-toggle rds-control-suffix />
      }
      <rds-datepicker #datePickerFrom></rds-datepicker>
    </rds-form-field>
    <p class="ui-label-m separator c-700-grey-1">to</p>
    <rds-form-field labelPosition="none" size="m">
      <input rdsInput [rdsDatepicker]="datePickerTo" formControlName="toDate" />
      @if (filtersForm.controls.toDate.value) {
        <rds-datepicker-clear
          rds-control-suffix
          [forDatepicker]="datePickerTo" />
      } @else {
        <rds-datepicker-toggle rds-control-suffix />
      }
      <rds-datepicker #datePickerTo></rds-datepicker>
    </rds-form-field>
  </div>
  <div class="dates-wrapper mt-2">
    <rds-form-field labelPosition="none" size="m">
      <input
        rdsInput
        [rdsTimepicker]="timepickerFrom"
        formControlName="fromTime" />
      <rds-timepicker #timepickerFrom />
      <rds-timepicker-clear
        rds-control-suffix
        [forTimepicker]="timepickerFrom" />
    </rds-form-field>
    <p class="ui-label-m separator c-700-grey-1">to</p>
    <rds-form-field labelPosition="none" size="m">
      <input rdsInput [rdsTimepicker]="timepickerTo" formControlName="toTime" />
      <rds-timepicker #timepickerTo />
      <rds-timepicker-clear rds-control-suffix [forTimepicker]="timepickerTo" />
    </rds-form-field>
  </div>

  @if (roomFilters && roomFilters.roomTypes) {
    <p class="ui-label-m-bold lmt-2 c-700-grey-1">Room Type</p>
    <ng-container [formGroup]="filtersForm.controls.roomTypes">
      @for (roomType of roomFilters.roomTypes; track roomType.name) {
        <rds-checkbox [formControlName]="roomType.name" size="m">
          {{ roomType.name }}
        </rds-checkbox>
      }
    </ng-container>
  }

  @if (roomFilters && roomFilters.roomFeatures) {
    <p class="ui-label-m-bold lmt-2 c-700-grey-1">Room must include</p>
    <ng-container [formGroup]="filtersForm.controls.roomFeatures">
      @for (roomFeature of roomFilters.roomFeatures; track roomFeature.id) {
        <rds-checkbox [formControlName]="'' + roomFeature.id" size="m">
          {{ roomFeature.name }}
        </rds-checkbox>
      }
    </ng-container>
  }
</div>
@if (isMobileScreen?.matches) {
  <div class="button-section">
    <button rds-secondary-button size="m" (click)="resetForm()">
      Clear All
    </button>
    <button rds-primary-button size="m" (click)="applyFilters()">Apply</button>
  </div>
}
