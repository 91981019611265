import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  OnInit,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import {
  RdsButtonModule,
  RdsIconComponent,
  RdsLinkComponent,
  RdsProgressSpinnerModule,
  RdsTabModule,
} from '@rds/angular-components';
import { switchMap } from 'rxjs';

import {
  BookingRequestBaseModel,
  BookingRequestCreateModel,
  RoomBookingsCalendarComponent,
} from '@bookly/shared';

import { RoomDetailsCardComponent } from '../../../rooms/components/room-details-card/room-details-card.component';
import { RoomsService } from '../../../rooms/services/rooms.service';
import { RoomBookingFormComponent } from '../../components/room-booking-form/room-booking-form.component';
import { BookingRequestService } from '../../services/booking-request.service';

@Component({
  selector: 'bus-booking-request-view',
  imports: [
    RoomBookingFormComponent,
    RdsProgressSpinnerModule,
    RdsIconComponent,
    RdsLinkComponent,
    RouterLink,
    RdsButtonModule,
    RoomDetailsCardComponent,
    RdsTabModule,
    RoomBookingsCalendarComponent,
  ],
  templateUrl: './booking-request-view.component.html',
  styleUrl: './booking-request-view.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BookingRequestViewComponent implements OnInit {
  readonly #roomsService = inject(RoomsService);
  readonly #bookingRequestService = inject(BookingRequestService);
  readonly #activatedRoute = inject(ActivatedRoute);
  readonly #router = inject(Router);
  readonly #destroyRef = inject(DestroyRef);
  protected readonly roomDetails = this.#roomsService.roomDetails;
  protected readonly roomLayouts = this.#roomsService.roomLayouts;
  protected readonly isRoomLoading = this.#roomsService.isRoomDetailsLoading;
  protected readonly isBookingRequestLoading =
    this.#bookingRequestService.isLoading;

  public ngOnInit() {
    if (!this.roomDetails()) {
      this.#activatedRoute.params
        .pipe(
          switchMap(({ roomId }) => this.#roomsService.getRoomData(roomId)),
          takeUntilDestroyed(this.#destroyRef)
        )
        .subscribe();
    }
  }

  protected createRoomBooking(requestData: BookingRequestBaseModel) {
    const request = {
      ...requestData,
      roomDexId: this.roomDetails()?.roomDexId,
    } as BookingRequestCreateModel;
    this.#bookingRequestService.createRoomBooking(request).subscribe(() => {
      void this.#router.navigate([
        'dashboard',
        'rooms',
        this.roomDetails()?.roomDexId,
        'confirm',
      ]);
    });
  }

  protected cancel() {
    void this.#router.navigate([
      'dashboard',
      'rooms',
      this.roomDetails()?.roomDexId,
    ]);
  }
}
