@let isMobileScreen = isMobileScreen$ | async;

<div
  class="filters-panel"
  [class]="showHideClass()"
  [class.mobile]="isMobileScreen?.matches">
  @if (isMobileScreen && isMobileScreen.matches) {
    <div class="header">
      <p class="ui-label-l-bold">Filters</p>
      <button rds-icon-button size="m" (click)="hideMenu()">
        <rds-icon icon="close"></rds-icon>
      </button>
    </div>
  }

  <bus-filter-form (filters)="applyFilters($event)"></bus-filter-form>
</div>
