import { BreakpointObserver } from '@angular/cdk/layout';
import { AsyncPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  EventEmitter,
  OnInit,
  Output,
  effect,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ReactiveFormsModule } from '@angular/forms';
import {
  RDS_DATE_FORMATS,
  RdsButtonModule,
  RdsCheckboxModule,
  RdsDateFormats,
  RdsDatepickerClearComponent,
  RdsDatepickerComponent,
  RdsDatepickerInputDirective,
  RdsDatepickerToggleComponent,
  RdsFormFieldModule,
  RdsTimepickerClearComponent,
  RdsTimepickerComponent,
  RdsTimepickerInputDirective,
} from '@rds/angular-components';
import { EMPTY, debounceTime, distinctUntilChanged, switchMap } from 'rxjs';

import { RoomQueryParams } from '@bookly/shared';

import { FiltersFormService } from './filters-form.service';

const CUSTOM_DATE_FORMATS: RdsDateFormats = {
  parse: {
    dateInput: null,
  },
  display: {
    dateInput: {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    },
    monthYearLabel: { year: 'numeric', month: 'short' },
    dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric' },
    monthYearA11yLabel: { year: 'numeric', month: 'long' },
  },
};

@Component({
  selector: 'bus-filter-form',
  imports: [
    RdsFormFieldModule,
    RdsDatepickerClearComponent,
    RdsDatepickerToggleComponent,
    RdsDatepickerComponent,
    RdsDatepickerInputDirective,
    RdsTimepickerComponent,
    RdsTimepickerInputDirective,
    RdsTimepickerClearComponent,
    RdsCheckboxModule,
    ReactiveFormsModule,
    RdsButtonModule,
    AsyncPipe,
  ],
  providers: [
    {
      provide: RDS_DATE_FORMATS,
      useValue: CUSTOM_DATE_FORMATS,
    },
  ],
  templateUrl: './filter-form.component.html',
  styleUrl: './filter-form.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterFormComponent implements OnInit {
  @Output() filters = new EventEmitter<RoomQueryParams>();
  readonly #breakpoint = inject(BreakpointObserver);
  protected isMobileScreen$ = this.#breakpoint.observe('(max-width: 768px)');

  readonly #filtersFormService = inject(FiltersFormService);
  readonly #destroyRef = inject(DestroyRef);

  readonly roomFiltersData = this.#filtersFormService.roomFiltersData;
  readonly buildings = this.#filtersFormService.buildings;
  readonly floors = this.#filtersFormService.floors;
  readonly filtersForm = this.#filtersFormService.filtersForm;

  constructor() {
    effect(() => {
      if (this.roomFiltersData()) {
        this.#filtersFormService.populateRoomFilters();
      }
    });
  }

  ngOnInit() {
    this.#filtersFormService.siteIdValueChanges
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe();
    this.#filtersFormService.buildingIdValueChanges
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe();
    this.isMobileScreen$
      .pipe(
        switchMap(isMobile =>
          isMobile.matches ? EMPTY : this.filtersForm.valueChanges
        ),
        distinctUntilChanged(),
        debounceTime(1000),
        takeUntilDestroyed(this.#destroyRef)
      )
      .subscribe(() => this.applyFilters());
  }

  protected applyFilters() {
    this.filters.emit(this.#filtersFormService.getSelectedFormData());
  }

  protected resetForm() {
    this.#filtersFormService.resetForm();
  }
}
