import { NgOptimizedImage } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RdsFormFieldModule, RdsIconComponent } from '@rds/angular-components';

import { RoomDetailsModel, RoomLayoutsModel } from '@bookly/shared';

@Component({
  selector: 'bus-room-details-card',
  imports: [
    NgOptimizedImage,
    FormsModule,
    RdsFormFieldModule,
    RdsIconComponent,
    ReactiveFormsModule,
  ],
  templateUrl: './room-details-card.component.html',
  styleUrl: './room-details-card.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RoomDetailsCardComponent {
  @Input() public roomDetails!: RoomDetailsModel;
  @Input() public set roomLayouts(value: RoomLayoutsModel[] | null) {
    if (value) {
      this.layouts = value;
      const defaultLayout = value.find(
        layout =>
          layout.layoutDexUUID ===
          this.roomDetails.manageSpaceConfiguration?.defaultLayoutId
      );
      if (defaultLayout) {
        this.layoutControl.patchValue(defaultLayout);
      }
    }
  }
  layouts: RoomLayoutsModel[] = [];
  layoutControl = new FormControl<RoomLayoutsModel | null>(null);
}
