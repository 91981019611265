import { NgOptimizedImage } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { RdsCardModule } from '@rds/angular-components';

import { RoomsListModel } from '@bookly/shared';

@Component({
  selector: 'bus-room-card',
  imports: [NgOptimizedImage, RdsCardModule],
  templateUrl: './room-card.component.html',
  styleUrl: './room-card.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RoomCardComponent {
  @Input() public room!: RoomsListModel;
}
